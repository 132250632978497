<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="省份:">
                        <el-select v-model="filters.provinceCode" filterable clearable style="width: 150px;">
                            <el-option v-for="item in options.province"  :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                        <el-form-item label="周期:">
                            <el-select v-model="filters.belongPeriod" clearable style="width: 150px;">
                                <el-option v-for="item in options.belongPeriod" :key="item.id" :label="item.name"
                                    :value="item.id"></el-option>
                            </el-select>
                    </el-form-item>
                    <el-form-item label="是否逾期:">
                        <el-select v-model="filters.status" clearable style="width: 150px;">
                            <el-option v-for="item in options.status" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="合同编号:">
                        <el-input v-model="filters.contractNo" @input="(e) => (filters.contractNo = utils.validForbid(e))" clearable placeholder="请输入合同编号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table id="exportTab" :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                   @selection-change="currentChange" :highlight-current-row='true'  :cell-style="rowClass">
            <el-table-column type="selection" width="55" :disabled="invoiceCompanyId"></el-table-column>
            <el-table-column type='index' label="序号" width='45' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="contractNo" width='128' label="合同编号" align="center"></el-table-column>
            <el-table-column prop="areaData" width='140' label="（县）区" align="center"></el-table-column>
            <el-table-column prop="customerName" width='160' label="业主" align="center"></el-table-column>
            <el-table-column prop="contractStartDay" width='180' label="履约起始时间" align="center"></el-table-column>
            <el-table-column prop="contractPeriod" width='55' label="合同期限" align="center"></el-table-column>
            <el-table-column prop="settlementPeriod" width='55' label="结算周期" align="center"></el-table-column>
            <el-table-column prop="belongPeriod" width='70' label="所属结算周期" align="center"></el-table-column>
            <el-table-column prop="calculateStartDay" width='190' label="结算时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.calculateStartDay }} - {{ scope.row.calculateEndDay }}
                </template>
            </el-table-column>
            <el-table-column prop="totalProjectArea" width='110' label="图审总面积（平方米）" align="center"></el-table-column>
            <el-table-column prop="receivableAmount" width='90' label="应收金额" align="center"></el-table-column>
            <el-table-column prop="actualAmount" width='90' label="实收金额" align="center">
                <template slot-scope="scope">
                    <el-button :disabled="scope.row.actualAmount === 0" type="text"
                        @click="checkActualAmount(scope.row)">{{
                            scope.row.actualAmount
                        }}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="collectionAmount" width='100' label="待催收金额" align="center"></el-table-column>
            <el-table-column prop="invoiceStatus" width='60' label="发票" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="onInvoice(scope.row)" :disabled="scope.row.invoiceCount === 0">
                      {{ scope.row.invoiceCount }}
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column prop="status" width='80' label="当前状态" align="center">
                <template slot-scope="scope">
                    {{ scope.row.status === 0 ? '正常' : scope.row.status === 1 ? '逾期' : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="rushNoticeCount" width='90' label="催收通知（次）" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="checkRushNoticeList(scope.row)">{{
                        scope.row.rushNoticeCount === 0 ? '未发' : scope.row.rushNoticeCount
                    }}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="warningNoticeCount" width='90' label="警示通知（次）" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="checkWarningNoticeList(scope.row)">{{
                        scope.row.warningNoticeCount === 0 ? '未发' : scope.row.warningNoticeCount
                    }}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="projectCount" width='90' label="项目数" align="center">
                <template slot-scope="scope">
                    <el-button type="text" :disabled="scope.row.projectCount === 0"
                        @click="getProjectList(scope.row)">{{
                            scope.row.projectCount
                        }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="dataCount">
            </el-pagination>
        </el-col>
        <!-- 发票列表 -->
        <el-dialog title="申请开票" :visible.sync="dialog.invoice.show" v-model="dialog.invoice.show"
                   :close-on-click-modal="false" width='88%' >
          <el-table
                    :data="dialog.invoice.list"
                    border
                    style="width: 100%"
                    v-loading="listLoading"
                    ref="multipleTable"
                    :highlight-current-row='true'
                    :cell-style="rowClass"
                >
<!--            @current-change='currentInvoiceChange'-->
                  <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
                  <el-table-column prop="issuerType" label="开票类型" width='100' align="center" show-overflow-tooltip>
                    <template slot-scope='scope'>
                      <el-tag v-if="scope.row.issuerType == 0" >个人</el-tag>
                      <el-tag v-if="scope.row.issuerType == 1" type="success">企业</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="invoiceTpye" label="发票类型" width='120' align="center" show-overflow-tooltip>
                    <template slot-scope='scope'>
                      <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="invoiceMoney" label="开票金额" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="invoiceTitle" label="发票抬头" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="externalPersonPhone" label="手机号" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="openTime" label="开票时间" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="invoiceStatus" label="收款匹配状态" width='120' align="center" show-overflow-tooltip>
                    <template slot-scope='scope'>
                      <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>
                      <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>
                      <el-tag v-if="scope.row.invoiceStatus == 2" >全匹配</el-tag>
                    </template>
                  </el-table-column>
                </el-table>
          <div slot="footer" class="dialog-footer">
            <!--                <el-button @click.native="dialog.invoice.show = false">关闭</el-button>-->
          </div>
        </el-dialog>
        <!-- 申请发票-->
        <el-dialog title="申请发票" :visible.sync="dialog.invoiceOpen.show" v-model="dialog.invoiceOpen.show"
            :close-on-click-modal="false" width='40%'>
          <el-form :model="invoiceApply" label-width="320px" :rules="invoiceApplyRules" ref="invoiceApplyRef">
            <el-form-item :label="'可开票金额 （最低可开金额:'+(invoiceMoneyOne?invoiceMoneyOne:0)+'）:'"  prop="">
              <el-input  :disabled="true" :placeholder="allowAmountInvoiced" style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="开票金额:" prop="amountInvoiced">
              <el-input v-model="invoiceApply.amountInvoiced" placeholder="开票金额" style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="开票抬头:" prop="amountInvoiced">
              <el-input v-model="invoiceApply.invoicingHeader" placeholder="开票抬头" style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="购方税号:" prop="taxNumber">
              <el-input v-model="invoiceApply.taxNumber" placeholder="购方税号" style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="手机号:" prop="phone">
              <el-input v-model="invoiceApply.phone" placeholder="手机号" style='width:200px'></el-input>
            </el-form-item>
            <el-form-item label="邮箱:" prop="email">
              <el-input v-model="invoiceApply.email" placeholder="邮箱" style='width:200px'></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click.native="invoiceOpenColsed">取消</el-button>
            <el-button type="primary" @click="addInvoice" :loading="addLoading">确认</el-button>
          </div>
        </el-dialog>
        <!-- 催收通知列表 -->
        <el-dialog title="催收通知" :visible.sync="dialog.urgeNotice.show" v-model="dialog.urgeNotice.show"
            :close-on-click-modal="false" width='40%' >
            <ul>
                <li v-for="item in notificationlist" :key="item.id">
                    <div>
                        {{item.content}}
                        <br>
                        系统发送时间： {{item.pushTime}}  发送{{item.status == "1" ? "成功" : "失败" }}
                        <br>
                        接收人：{{item.receiveUserName}}
                    </div>
                    <br>

                </li>
            </ul>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.urgeNotice.show = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 警示通知列表 -->
        <el-dialog title="警示通知" :visible.sync="dialog.warningNotice.show" v-model="dialog.warningNotice.show"
            :close-on-click-modal="false" width='40%'>
           <ul>
                <li v-for="item in notificationlist" :key="item.id">
                    <div>
                        {{item.content}}
                        <br>
                        系统发送时间： {{item.pushTime}}  发送{{item.status == "1" ? "成功" : "失败" }}
                        <br>
                        接收人：{{item.receiveUserName}}
                    </div>
                    <br>
                </li>
            </ul>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.warningNotice.show = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 流水匹配 -->
        <el-dialog title="流水匹配" :visible.sync="dialog.journalAccount.show" v-model="dialog.journalAccount.show"
            :close-on-click-modal="false" width='50%'>
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px" style='min-width:800px;height:500px;'>
                <el-form-item label="匹配金额:" prop="matchAmount">
                    <el-input v-model="addForm.matchAmount" placeholder="匹配金额" style='width:200px'></el-input>
                </el-form-item>
                <el-form-item label="收款单:" prop="paymentName">
                    <!-- <el-select @change="changeRevenuePaymentData" v-model="addForm.revenuePaymentsId" remote :remote-method="getChangePaymentsByNoMatchList" style="width:100%">
                        <el-option v-for="item in revenuePaymentData" :key="item.id" :label="item.paymentName"
                            :value="item.id" ></el-option>
                    </el-select> -->
                     <el-autocomplete v-model="paymentName" value-key="paymentName" @select="handleSelect"
                        :fetch-suggestions="getChangePaymentsByNoMatchList" style="width:100%"></el-autocomplete>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.journalAccount.show = false">取消</el-button>
                <el-button type="primary" @click="addSubmitJournalAccount" :loading="addLoading">确认</el-button>
            </div>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.journalAccount.show = false">关闭</el-button>
            </div> -->
        </el-dialog>
        <!-- 匹配记录 -->
        <el-dialog title="实收金额匹配记录" :visible.sync="dialog.actualAmount.show" v-model="dialog.actualAmount.show"
            :close-on-click-modal="false" width='50%'>
           <el-table
            :data="revenuePaymentList"
            highlight-current-row
            v-loading="listLoading"
            style="width: 100%;"
        >
            <el-table-column prop="tranFlowNo" label="交易流水号" width="" ></el-table-column>
            <el-table-column prop="matchAmount" label="匹配金额" width="" ></el-table-column>
            <el-table-column prop="tranDate" label="交易时间" width="" ></el-table-column>
        </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.actualAmount.show = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 项目列表 -->
        <el-dialog title="项目列表" :visible.sync="dialog.projectList.show" v-model="dialog.projectList.show"
            :close-on-click-modal="false" width='88%'>
            <el-table :data="dialog.projectList.list" border style="width: 100%" v-loading="listLoading"
                ref="multipleTable" :highlight-current-row='true'>
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="projectNo" width='160' label="项目编号" align="center"></el-table-column>
                <el-table-column prop="areaName" width='160' label="（县）区" align="center"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
                <el-table-column prop="contractNo" width='140' label="关联合同编号" align="center"></el-table-column>
                <el-table-column prop="projectContacts" width='160' label="项目联系人" align="center"></el-table-column>
                <el-table-column prop="projectPhone" width='160' label="联系方式" align="center"></el-table-column>
                <el-table-column prop="projectUnitPrice" width='100' label="项目单价（元）" align="center"></el-table-column>
                <el-table-column prop="projectArea" width='160' label="图审面积（平方米）" align="center"></el-table-column>
                <!-- <el-table-column prop="contractNo" width='160' label="项目金额（元）" align="center"></el-table-column> -->
                <el-table-column prop="ddIdProjectStatusName" width='100' label="项目状态" align="center">
                  <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 34">立项</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 35">上传资料</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 44">初审任务分配</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 45">初审意见填写</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 46">初审意见提交完成</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 47">初审报告签章完成</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 50">上传复核资料</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 51">复核任务分配</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 52">复核意见填写</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 53">复核意见提交完成</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 54">图审报告签章完成</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 55">审结</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 134">初审报告完成</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectStatus == 135">复核报告完成</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="conclusionTime" width='120' label="项目归档日期" align="center"></el-table-column>
              <el-table-column prop="verificationStatus" width='120' label="项目匹配状态" align="center">
                <template slot-scope='scope'>
                  <el-tag v-if="scope.row.verificationStatus == 0" type="danger">未匹配</el-tag>
                  <el-tag v-if="scope.row.verificationStatus == 1" type="success">部分匹配</el-tag>
                  <el-tag v-if="scope.row.verificationStatus == 2">全匹配</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="verificationStatus" width='120' label="开票状态" align="center">
                <template slot-scope='scope'>
                  <el-tag v-if="!scope.row.isOpenInvoice " type="danger">未开票</el-tag>
                  <el-tag v-else type="success">已开票</el-tag>
                </template>
              </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialog.projectList.show = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
  getFinancialReceivableListPage,
  getNotificationListPageByContractIdAndCycleId,
  getPaymentsByNoMatchList,
  addRevenuePaymentDetail,
  getPaymentDetailListPageByCycleId,
  getProjectByCycleId,
  getProjectByContractId,
  getAreaDictionaryList,
  getByNamespace, getInvoiceList, addFinancialInvoice, getProjectConclusionReportList,
} from '@/api/api'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
    components: { Toolbar },
    data() {
        return {
          dataCount:undefined,
            utils: '',
            addLoading: false,
            buttonList: [],
            needData: '',
          valNeedData:'',
            filters: {
                provinceCode: undefined,
                status: undefined,
                contractNo: undefined,
                belongPeriod: undefined,
            },
          invoiceOpen:[
            { name: '个人', value: 0 },
            { name: '企业', value: 1 },
          ],
            //发票申请
            invoiceApply:{
              // invoiceOpen:undefined,//开票类型
              // invoiceType:undefined,//发票类型
              // county:undefined,//县
              allowAmountInvoiced:undefined,//可开票金额
              amountInvoiced:undefined,//开票金额
              invoicingHeader:undefined,//开票抬头
              taxNumber:undefined,//购方税号
              phone:undefined,//手机号
              email:undefined,//邮箱
            },
          invoiceApplyRules: {
            // invoiceOpen: [{ required: true, message: '', trigger: 'blur' }],//开票类型
            // invoiceType: [{ required: true, message: '', trigger: 'blur' }],//发票类型
            // county: [{ required: true, message: '', trigger: 'blur' }],//县
            // allowAmountInvoiced: [{ required: true, message: '该内容不能为空！', trigger: 'blur' }],//可开票金额
            amountInvoiced: [{ required: true, message: '该内容不能为空！', trigger: 'blur' },
              { validator: this.validateAmountInvoiced, trigger: 'blur' }],//开票金额
            invoicingHeader: [{ required: true, message: '该内容不能为空！', trigger: 'blur' }],//开票抬头
            taxNumber: [{ required: true, message: '该内容不能为空！', trigger: 'blur' }],//购方税号
            phone: [{ required: true, message: '该内容不能为空！', trigger: 'blur' },{
              validator:this.validatePhone,trigger: 'blur' }],//手机号
            email: [{ required: true, message: '该内容不能为空！', trigger: 'blur' }, {
              validator: this.validateToEmail, trigger: 'blur' }],//邮箱
          },
          allowAmountInvoiced:undefined,//
            tableData: [],
          exportList:[],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            dialog: {
                // 催收通知
                urgeNotice: {
                    show: false
                },
                // 警示通知
                warningNotice: {
                    show: false
                },
                // 发票
                invoice: {
                    show: false,
                  list:[]
                },
              // 开票
              invoiceOpen: {
                show: false
              },
                // 实收金额记录
                actualAmount: {
                    show: false
                },
                // 流水匹配
                journalAccount: {
                    show: false
                },
                // 项目数列表
                projectList: {
                    show: false,
                    list: []
                }
            },
            options: {
                province: [],
                // 是否逾期
                status: [
                    {
                        name: '否',
                        id: 0
                    },
                    {
                        name: '是',
                        id: 1
                    },
                ],
                 // 是否逾期
                belongPeriod: [
                    {
                        name: '第一周期',
                        id: 1
                    },
                    {
                        name: '第二周期',
                        id: 2
                    },
                    {
                        name: '第三周期',
                        id: 3
                    },
                    {
                        name: '第四周期',
                        id: 4
                    },
                ],
            },
             // 所有银行流水凭证
            revenuePaymentData: [],
            paymentName:'',
            revenuePaymentList: [],
            notificationlist:[],
            addForm: {
                revenuePaymentsId:undefined,
                matchAmount:0,
                financialReceivableCycleId:undefined,
            },
            addRules: {},
          invoiceMatchList: [],//发票匹配
          receivableCycleIds:[],
          invoiceCompanyId:true,
          invoiceMoneyOne:undefined,

          exportBoolean: false,
          exportName: [
            { header: '合同编号', property: 'contractNo' },
            { header: '县（区）', property: 'areaData' },
            { header: '业主', property: 'customerName' },
            { header: '履约起始时间', property: 'contractStartDay' },
            { header: '合同期限', property: 'contractPeriod' },
            { header: '结算周期', property: 'settlementPeriod' },
            { header: '所属结算周期', property: 'belongPeriod' },
            { header: '结算时间', property: 'calculateStartDay' },
            { header: '图审总面积（平方米）', property: 'totalProjectArea' },
            { header: '应收金额', property: 'receivableAmount' },
            { header: '实收金额', property: 'actualAmount' },
            { header: '待催收金额', property: 'collectionAmount' },
            { header: '发票', property: 'invoiceStatus' },
            { header: '当前状态', property: 'status' },
            { header: '催收通知（次）', property: 'rushNoticeCount' },
            { header: '警示通知（次）', property: 'warningNoticeCount' },
            { header: '项目数', property: 'projectCount' },
          ],
          isExporting: false,
          recordInvoice:'',
          invoiceCount:0,
          // pageTotal: Math.ceil(this.dataCount/this.pages.pageSize)
        }
    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
        this.init();
    },
    methods: {
      validateAmountInvoiced(rule, value, callback){
        if (this.invoiceApply.amountInvoiced>this.allowAmountInvoiced){
          callback(new Error('开票金额不能超过可开票金额！'))
        }else{
          if (this.invoiceApply.amountInvoiced < this.invoiceMoneyOne){
            callback(new Error('开票金额不能小于第一个月！'))
          }
          callback()
        }
      },
      validateMobileNumber(mobileNumber)
          {
            const pattern = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/;
            return pattern.test(mobileNumber);
          },
       validateEmail(email) {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(email);
        },
      validateToEmail (rule, value, callback) {
        // 自定义验证函数
        const email = this.invoiceApply.email;
        if (email){
          this.$refs.invoiceApplyRef.clearValidate('phone');
          if (this.validateEmail(email)){
            callback()
          }else {
            callback(new Error('邮箱格式错误，请重新输入！'))
          }
        }


      },
      validatePhone(rule, value, callback) {
        // 自定义验证函数
        const phone = this.invoiceApply.phone;
        const email = this.invoiceApply.email;
        if (phone) {
          this.$refs.invoiceApplyRef.clearValidate('email');
          if (this.validateMobileNumber(phone)) {
            callback()
          } else {
            callback(new Error('手机号格式错误，请重新输入！'))
          }
        }
      },

      //发票

      //申请发票
     async applyInvoice(){
        if (!this.needData||this.needData.length<=0 ) {
          this.$message({
            message: '请点击左侧方框选择数据！',
            type: "error"
          });
          return
        }
        if (this.invoiceMoneyOne === 0) {
          this.$message({
            message: '最早一月可开票金额为零，请重新选择！',
            type: "error"
          });
          this.invoiceMoneyOne = undefined
          return
        }
        if (this.allowAmountInvoiced === 0) {
          this.$message({
            message: '可开票金额为零，请重新选择！',
            type: "error"
          });
          this.allowAmountInvoiced=undefined
          return
        }
        let params = {
          contractId: this.needData[0].contractId,
          pageIndex: 1,//页码
          pageSize: 15,//每页条数
        }
        const res = await getInvoiceList(params)
        let result = res.data;
        if (result.success) {
          const response = result.response
          const data = response.data
          console.log(data, 'dsjdfhskaf')
          if (data) {
            const arrLen = data[0]
            console.log(arrLen, 'fksljfd11111111111')
            this.recordInvoice = arrLen
          }
        }

        this.invoiceApply ={
          // invoiceOpen:undefined,//开票类型
          // invoiceType: undefined,//发票类型
          // county: undefined,//县
          allowAmountInvoiced: undefined,//可开票金额
          amountInvoiced: undefined,//开票金额
          invoicingHeader: this.recordInvoice.invoiceTitle,//开票抬头
          taxNumber: this.recordInvoice.invoiceFax,//购方税号
          phone: this.recordInvoice.externalPersonPhone,//手机号
          email: this.recordInvoice.externalPersonEmail,//邮箱
        },

        this.dialog.invoiceOpen.show=true
      },
      invoiceOpenColsed(){
        this.dialog.invoiceOpen.show= false;
        this.allowAmountInvoiced = [];
        this.getDataList()
        this.needData=undefined
        this.invoiceMoneyOne=null
      },
      addInvoice(){
        this.$refs.invoiceApplyRef.validate(valid => {
          if (valid) {
            this.addLoading=true
            let params = {
              companyId:this.needData[0].companyId,//所属公司
              contractId: this.needData[0].contractId,//所属合同
              externalPersonEmail: this.invoiceApply.email,//收件人邮箱
              externalPersonPhone: this.invoiceApply.phone,//收件人手机号
              invoiceFax: this.invoiceApply.taxNumber,//购方税号
              invoiceMoney: +this.invoiceApply.amountInvoiced,//开票金额
              invoiceTitle: this.invoiceApply.invoicingHeader,//发票抬头
              receivableCycleIds:this.receivableCycleIds,//所选账单ID组
            }
            console.log(this.receivableCycleIds,'账单ID组')
            addFinancialInvoice(params).then(res => {
              if (res.data.success) {
                this.$message({
                  message: '申请发票成功',
                  type: "success"
                });
                this.getDataList();
                this.invoiceOpenColsed()
                this.addLoading = false
              } else {
                this.$message({
                  message: '申请发票失败',
                  type: "error"
                });
                this.addLoading=false
                this.invoiceOpenColsed()
              }
            })

          }
        })
      },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        async init() {
            // 获取省份
            await getAreaDictionaryList({ parentId: 1 }).then(res => {
                this.options.province = res.data.response.reverse();
            })
            const [invoiceStatusRes, statusRes] = await Promise.all(
                [getByNamespace({ namespace: 'BizService.Finance.InvoiceStatus' })],
                [getByNamespace({ namespace: 'BizService.Finance.Status' })]
            )

        },
        // 列表查询接口
        getDataList() {
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                provinceCode: this.filters.provinceCode,
                status: this.filters.status,
                contractNo: this.filters.contractNo,
                belongPeriod: this.filters.belongPeriod,
            }
            getFinancialReceivableListPage(params).then(res => {
                var result = res.data
                if (result.success) {
                    const response = result.response
                    this.dataCount = response.dataCount
                    const data = response.data
                    this.tableData = data
                    // .filter(item=>{(item.receivableAmount-item.invoiceAmount)>0})
                  // console.log(this.tableData,'this.tableData')
                }
            })
        },
        onInvoice (val) {
        // console.log(this.needData,'needData')
          this.invoiceMatchList=[]
          this.dialog.invoice.show = true;
        let params = {
          invoiceIds: val.invoiceIds,//发票ID组（逗号隔开）
          pageIndex: 1,//页码
          pageSize: 40,//每页条数
        }
        getInvoiceList(params).then(res => {
          let result = res.data;
          if (result.success) {
            const response = result.response
            const data = response.data
            this.invoiceMatchList = data
            this.dialog.invoice.list = data
          }
        })


      },
      //导出汇总
      async exportAll () {
        for (let i = 1; i < ((this.dataCount / this.pages.pageSize) < 1 ? 2 : +Math.ceil(this.dataCount / this.pages.pageSize)) + 1; i++) {
          let params = {
            pageIndex: i,
            pageSize: this.pages.pageSize,
            provinceCode: this.filters.provinceCode,
            status: this.filters.status,
            contractNo: this.filters.contractNo,
            belongPeriod: this.filters.belongPeriod,
          }
          const res = await getFinancialReceivableListPage(params)
          var result = res.data
          if (result.success) {
            this.exportList.push(...result.response.data)
          }
          setTimeout(() => {

          }, 100)

        }
        console.log(this.exportList, 'exportList')
      },
      exportToExcel (dataArray, headersMapping) {
        // 从映射中提取自定义表头
        const headers = headersMapping.map(mapping => mapping.header);

        // 从数据中筛选并映射对应属性
        const filteredData = dataArray.map(obj =>
            headersMapping.reduce((acc, mapping) => {
              const { header, property } = mapping;
              if (Object.prototype.hasOwnProperty.call(obj, property)) {
                acc[header] = obj[property];
              }
              return acc;
            }, {})
        );

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = '应收款账单.xlsx';

        if (window.navigator.msSaveOrOpenBlob) {
          // For IE/Edge browsers
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // For other browsers
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
      //导出
      async exportListData () {

        if (this.isExporting) {
          console.log('导出操作仍在进行中...');
          this.$message.error('导出操作仍在进行中...')
          return; // 如果导出仍在进行中，则退出函数
        }
        this.isExporting = true; //（isExporting是一个用于控制按钮状态的变量）
        await this.exportAll()
        setTimeout(() => {
          this.exportToExcel(this.exportList, this.exportName)
          this.isExporting = false;
          this.exportList = []
        }, 0)

      },
        // 催收通知
        checkRushNoticeList(row) {
             var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                ddIdTemplateType: 119,
                contractId: row.contractId,
                cycleId: row.id,
            }
            getNotificationListPageByContractIdAndCycleId(params).then(res => {
                var result = res.data
                if (result.success) {
                    this.notificationlist = result.response.data
                }
            })
            this.dialog.urgeNotice.show = true;
        },
        checkWarningNoticeList(row) {
             var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                ddIdTemplateType: 120,
                contractId: row.contractId,
                cycleId: row.id,
            }
            getNotificationListPageByContractIdAndCycleId(params).then(res => {
                var result = res.data
                if (result.success) {
                    this.notificationlist = result.response.data
                }
            })
            this.dialog.warningNotice.show = true;
        },
        checkActualAmount(row) {
            getPaymentDetailListPageByCycleId({cycleId: row.id,pageIndex:1,pageSize:60}).then((res) => {
                this.revenuePaymentList = res.data.response.data;
            });
            this.dialog.actualAmount.show = true;
        },
        getProjectList(row) {
            getProjectByCycleId({ cycleId: row.id,pageIndex:1,pageSize:60 }).then(res => {
                if (res.data.success) {
                    this.dialog.projectList.list = res.data.response.data;
                }
            })
            this.dialog.projectList.show = true;
        },
        addJournalAccount() {
            console.log("111");
            if (this.needData ===null || !this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.addForm = {
                financialReceivableCycleId:this.needData.id,
                matchAmount:0,
                revenuePaymentsId:undefined
            }
            this.dialog.journalAccount.show = true;
        },
        addSubmitJournalAccount() {
             this.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    console.log(this.addForm);
                    // this.addForm.revenuePaymentsId =  this.addForm.revenuePaymentsId.id;
                    let params = {
                        ...this.addForm
                    }
                    this.addLoading = true
                    addRevenuePaymentDetail(params).then(res => {
                        if (res.data.success) {
                            this.$message({
                                message: res.data.message,
                                type: "success"
                            });
                            this.getDataList();
                            // this.addDialogClose();
                            this.dialog.journalAccount.show = false;
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: "error"
                            });
                        }
                        this.addLoading = false
                    })

                }
            })
        },
        handleSelect(val) {
            this.addForm.revenuePaymentsId = val.id;
            console.log(val,'val');
        },
        getChangePaymentsByNoMatchList(queryString, cb) {
            getPaymentsByNoMatchList({keyWord:queryString}).then(res => {
                if (res.data.success) {
                    this.revenuePaymentData = res.data.response;
                    let revenuePaymentData = this.revenuePaymentData;
                    let results = revenuePaymentData;
                    cb(results);
                }
            })
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.paymentName.indexOf(queryString) === 0);
            };
        },
        // 查询
        getListData() {
            this.pages.pageIndex = 1
            this.getDataList();
        },

        currentChange(val) {
           if (val){
             this.needData = val
             console.log(val, 'tableVal')

             if (val.length > 1) {
               try {
                 this.checkCompanyIdConsistency(val);
                 console.log("ID一致。");
               } catch (error) {
                 this.$message({
                   message: '公司id不一致，请重新选择',
                   type: "error"
                 });
               }
             }
             if (val.length>1){
                  // 使用Array.sort()方法对数组进行排序，按照calculateStartDay属性升序排列
               const aaa = val.sort((a, b) => new Date(a.calculateStartDay) - new Date(b.calculateStartDay));
               console.log(aaa,'aaa')
               this.invoiceMoneyOne = +((aaa[0].receivableAmount-aaa[0].invoiceAmount).toFixed(2))
               this.receivableCycleIds = aaa.map(item => item.id)
               console.log(this.receivableCycleIds,'111')
             }else {
               this.receivableCycleIds = val.map(item=>item.id)
             }
             if (val.length>0){
               const allMoney = val.map(item => item.invoiceAmount)
               const receivableAmount = val.map(item=>item.receivableAmount)
               const a= allMoney.reduce((accumulator, currentValue) => accumulator + currentValue);
               const b= receivableAmount.reduce((accumulator, currentValue) => accumulator + currentValue);
               this.allowAmountInvoiced = +((b-a).toFixed(2))
             }
             console.log(this.allowAmountInvoiced, 'allow')
           }
        },
        //判断是否为同一companyId
      checkCompanyIdConsistency (arr) {
        if (arr.length === 0) {
          throw new Error("数组为空，无法检查公司ID一致性。");
        }
        const firstCompanyId = arr[0].companyId;
        for (let i = 1; i < arr.length; i++) {
          if (arr[i].companyId !== firstCompanyId) {
            this.invoiceCompanyId = false
            throw new Error("公司ID不一致，数组包含不同的公司ID值。");
          }
        }
      },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function (row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
                ? ""
                : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    }
}
</script>

<style lang="stylus" scoped>

</style>
